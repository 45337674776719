import { Grid } from '@material-ui/core';
import { AutocompleteArrayInput, required } from 'react-admin';
import { getPaymentOptions } from '../../../helpers/getPaymentOptions';

const PaymentOptions = ({ getSource }) => (
  <Grid item style={{ padding: '0 16px' }} xs={12}>
    <AutocompleteArrayInput
      choices={getPaymentOptions()}
      format={(value) =>{
        if (Array.isArray(value) && value.length === 0) {
          return [];
        }
        return value ? value.split(',').map((str) => str.toLowerCase().trim()) : '';
      }}
      fullWidth
      label="Payment option"
      parse={(value) => value.join(',')}
      source={getSource('paymentOption')}
      validate={[required()]}
      variant="standard"
      data-cy="PaymentOption"
    />
  </Grid>
);

export default PaymentOptions;
