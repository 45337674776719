import {
  BooleanInput,
  Create,
  FormDataConsumer,
  required,
  SimpleForm,
  TextInput,
  useGetList,
  useCreateController,
  useTranslate,
  DateTimeInput,
} from 'react-admin';
import { getSlug } from '../../helpers/getSlug';
import Product from './product/Product';
import { requiredDisablePast } from '../../helpers/dateUtils';
import { transform, onCampaignSave } from '../../helpers/campaignUtils';

export const CampaignCreate = (props) => {
  const { save } = useCreateController(props);
  const translate = useTranslate();

  const { data: couponData, loading, error } = useGetList('mc/client/product');

  if (loading) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  const onSave = (newCampaign) => onCampaignSave(couponData, newCampaign, save, translate('ra.action.disable_products_confirmation'));

  const defaultValues = {
    defaultCampaign: false,
    isDisable: false,
    slugName: '',
    expiryDate: null,
  };

  return (
    <Create {...props} transform={transform}>
      <SimpleForm onSubmit={onSave} defaultValues={defaultValues}>
        <FormDataConsumer>
          {({ formData }) => {
            const slugName = getSlug(formData?.campaignName);
            const defaultCampaign = formData?.defaultCampaign;
            formData.slugName = slugName;

            if (defaultCampaign) {
              if (formData.expiryDate) {
                formData.expiryDate = null;
              }

              if (formData.isDisable) {
                formData.isDisable = false;
              }
            }

            return (<>
              <BooleanInput source="defaultCampaign" variant="standard" margin="normal" />
              <TextInput
                  source="campaignName"
                  helperText={`Slug: ${slugName}`}
                  fullWidth
                  validate={[required()]}
                  variant="standard"
                  margin="normal"
              />
              <TextInput source="title" fullWidth validate={[required()]} variant="standard" margin="normal" />
              <TextInput source="subTitle" fullWidth variant="standard" margin="normal" />
              <BooleanInput source="isVipps" variant="standard" margin="normal" label="Is it a Vipps campaign?" />
              <BooleanInput source="compactView" variant="standard" margin="normal" label="Show campaign in a compact view? (Dropdown view)" />

              {!defaultCampaign && <DateTimeInput
                source="expiryDate"
                validate={[requiredDisablePast]}
                disabled={defaultCampaign}
                variant="standard"
                margin="normal"
              />}

              {!defaultCampaign && <BooleanInput
                fullWidth
                label="Disable this campaign"
                source="isDisable"
                disabled={defaultCampaign}
                variant="standard"
                margin="normal"
              />}
            </>);
          }}
        </FormDataConsumer>
        <Product couponData={couponData} />
      </SimpleForm>
    </Create>
  );
};

export default CampaignCreate;
