import { useRecordContext } from 'react-admin';

const PreviewUrlField = ({ source, prefix = '' }) => {
  const record = useRecordContext();
  const documentValue = record.document;
  let url = record[source];

  if (url && url.startsWith('http')) prefix = '';
  if (url && url.startsWith('/')) prefix = window.env.WEBSITE_URL;
  if (documentValue === 'shortUrl' && source === 'shortUrl') url = '/' + url;

  return (
    <a href={prefix + url} onClick={e => e.preventDefault()}>
      {record[source]}
    </a>
  );
};

export default PreviewUrlField;
