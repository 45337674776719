import { Grid } from '@material-ui/core';
import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';

const ProductUSPArray = ({ getSource }) => (
  <Grid item xs={12}>
    <ArrayInput source={getSource('productUSPArray')} label="Unique Selling Points">
      <SimpleFormIterator inline>
        <TextInput label="USP"/>
      </SimpleFormIterator>
    </ArrayInput>
  </Grid>
);

export default ProductUSPArray;
