import { useRecordContext } from 'react-admin';

const CampaignPreviewUrlField = ({ source }) => {
  const record = useRecordContext();
  const slugName = record.slugName;
  const url = window.env.SUBSCRIPTION_URL;
  const onClick = e => e.stopPropagation();
  return <a onClick={onClick} href={url + '/' + slugName} target="blank">{record[source]}</a>;
};

export default CampaignPreviewUrlField;
