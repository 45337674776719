import { useState } from 'react';
import {
  ArrayInput,
  FormDataConsumer,
  SimpleFormIterator,
  useRecordContext,
  TextInput,
} from 'react-admin';
import { Grid } from '@material-ui/core';
import { disablePayment } from '../../../helpers/campaignUtils';
import { getSlug } from '../../../helpers/getSlug';
import { isDefined } from '../../../helpers/isDefined';
import BasicInfo from './BasicInfo';
import Button from './Button';
import Coupon from './Coupon';
import Description from './Description';
import Image from './Image';
import Options from './Options';
import PaymentOptions from './PaymentOptions';
import Price from './Price';
import ProductUSPArray from './ProductUSPArray';

const Product = ({ couponData }) => {
  const [prices, setPrices] = useState([]);
  const record = useRecordContext();

  const populatePrices = (couponCode, couponNo, price) => {
    const currentPrice = prices.find(
      (element) =>
        element.couponCode === couponCode && element.couponNo === couponNo
    );

    if (!currentPrice) {
      setPrices([
        ...prices,
        {
          couponCode: couponCode,
          couponNo: couponNo,
          price: price,
        },
      ]);
    }
  };

  const updateFormDataCondition = (
    productIndex,
    currentProduct,
    scopedFormData
  ) => {
    let formUpdated = false;
    const subscriptionType = scopedFormData?.subscriptionType || '';
    const holdBackTelemarketing =
      scopedFormData?.holdBackTelemarketing || false;
    const paymentOption = scopedFormData?.paymentOption || '';

    if (disablePayment(subscriptionType) && paymentOption) {
      currentProduct.paymentOption = '';
      formUpdated = true;
    }

    if (holdBackTelemarketing && !currentProduct.skipUserCreation) {
      currentProduct.skipUserCreation = true;
      formUpdated = true;
    }

    if (formUpdated) {
      return currentProduct;
    }
    return null;
  };
  return (
    <ArrayInput source="products">
      <SimpleFormIterator>
        <FormDataConsumer>
          {({ formData, getSource, scopedFormData }) => {
            let productIndex = 0;
            let currentProduct = null;

            if (isDefined(scopedFormData?.slugName)) {
              const existingSlugs = record?.products?.map(
                (product) => product.slugName
              );

              if (
                !isDefined(
                  existingSlugs?.find(
                    (slug) => slug === scopedFormData.slugName
                  )
                )
              ) {
                scopedFormData.slugName = getSlug(scopedFormData?.name);
              }
            }

            try {
              productIndex = formData.products
                .map((product) => product.slugName)
                .indexOf(scopedFormData.slugName);
              currentProduct = updateFormDataCondition(
                productIndex,
                formData.products[productIndex],
                scopedFormData
              );
              if (currentProduct) {
                formData[productIndex] = currentProduct;
              } else {
                currentProduct = formData.products[productIndex];
              }
            } catch (e) {
              if (productIndex) {
                currentProduct = formData.products[productIndex];
              }
            }

            if (
              isDefined(scopedFormData?.couponCode) &&
              isDefined(scopedFormData?.couponNo) &&
              isDefined(scopedFormData?.price)
            ) {
              populatePrices(
                scopedFormData.couponCode,
                scopedFormData.couponNo,
                scopedFormData.price
              );
            }

            // Set company code
            if (isDefined(scopedFormData?.companyCode)) {
              scopedFormData.companyCode =
                scopedFormData.companyCode === ''
                  ? window.env.COMPANY_CODE
                  : scopedFormData.companyCode;
            }

            return (
              <Grid container spacing={1} style={{ margin: '16px 0' }}>
                <BasicInfo
                  getSource={getSource}
                  scopedFormData={scopedFormData}
                  subscriptionType={currentProduct?.subscriptionType}
                />

                <Options
                  getSource={getSource}
                  subscriptionType={currentProduct?.subscriptionType}
                  holdBackTelemarketing={currentProduct?.holdBackTelemarketing}
                  skipUserCreation={currentProduct?.skipUserCreation}
                />

                <Price getSource={getSource} />
                <Coupon
                  getSource={getSource}
                  couponData={couponData}
                  scopedFormData={scopedFormData}
                  prices={prices}
                  setPrices={setPrices}
                />

                <Grid
                  item
                  style={{
                    padding: '0 16px',
                    display:
                      scopedFormData?.subscriptionType === 'GROUP'
                        ? 'block'
                        : 'none',
                  }}
                  xs={12}
                  md={6}
                >
                  <TextInput
                    defaultValue=""
                    label="Group price"
                    source={getSource('displayPrice')}
                    variant="standard"
                  />
                </Grid>
                <Button getSource={getSource} scopedFormData={scopedFormData} />
                <Description getSource={getSource} />
                <ProductUSPArray getSource={getSource} />

                {!disablePayment(currentProduct?.subscriptionType) && (
                  <PaymentOptions
                    getSource={getSource}
                    paymentOption={currentProduct?.paymentOption}
                  />
                )}
                <Image getSource={getSource} />
              </Grid>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export default Product;
