import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  required,
  SimpleForm,
  TextInput,
  useGetList,
  useEditController,
  useTranslate,
  DateTimeInput,
} from 'react-admin';
import Product from './product/Product';
import { requiredDisablePast } from '../../helpers/dateUtils';
import { transform, onCampaignSave } from '../../helpers/campaignUtils';

export const CampaignEdit = (props) => {
  const { save } = useEditController(props);
  const translate = useTranslate();

  const { data: couponData, loading, error } = useGetList('mc/client/product');

  if (loading) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  const onSave = (newCampaign) => onCampaignSave(couponData, newCampaign, save, translate('ra.action.disable_products_confirmation'));

  return (
    <Edit {...props} transform={transform}>
      <SimpleForm onSubmit={onSave}>
        <FormDataConsumer>
          {({  formData }) => {
            const slug = formData?.slugName;
            const defaultCampaign = formData?.defaultCampaign;

            if (defaultCampaign) {
              if (formData.expiryDate) {
                formData.expiryDate = null;
              }

              if (formData.isDisable) {
                formData.isDisable = false;
              }
            }

            return (<>
              <BooleanInput source="defaultCampaign" variant="standard" margin="normal" />
              <TextInput source="campaignName" helperText={`Slug: ${slug}`} fullWidth variant="standard" margin="normal" />
              <TextInput source="title" fullWidth validate={[required()]} variant="standard" margin="normal" />
              <TextInput source="subTitle" fullWidth variant="standard" margin="normal" />
              <BooleanInput source="isVipps" variant="standard" margin="normal" label="Is it a Vipps campaign?" />
              <BooleanInput source="compactView" variant="standard" margin="normal" label="Show campaign in a compact view? (Dropdown view)" />

              <DateTimeInput
                source="expiryDate"
                validate={[requiredDisablePast]}
                disabled={defaultCampaign}
                variant="standard"
                margin="normal"
              />

              <BooleanInput
                fullWidth
                label="Disable this campaign"
                source="isDisable"
                disabled={defaultCampaign}
                variant="standard"
                margin="normal"
              />
              <Product couponData={couponData} />
            </>);
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default CampaignEdit;
