import { Grid } from '@material-ui/core';
import { RichTextInput } from 'ra-input-rich-text';

const Description = ({ getSource }) => (
  <Grid item style={{ padding: '0 16px' }} xs={12}>
    <RichTextInput
      fullWidth
      label="Description"
      source={getSource('text')}
      variant="standard"
    />
  </Grid>
);

export default Description;
