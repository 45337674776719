export function getPaymentOptions() {
  return window.env.PAYMENT_OPTIONS.split(',').map((option) => ({
    id: option.trim().toLowerCase(),
    name: titleCase(option.trim()),
  }));
}

function titleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
}
