import { RichTextInput } from 'ra-input-rich-text';
import { Edit, SimpleForm, TextField, TextInput, SimpleShowLayout } from 'react-admin';
import { defaultStyleSettings } from './RedirectCreate';

export const RedirectEdit = (props) => (
  <Edit {...props}>
    <SimpleForm >
        <SimpleShowLayout>
          <TextField
            source="shortUrl"
            label="From/Alias"
            {...defaultStyleSettings}
          />
          <TextField
            source="document"
            label="Type"
            {...defaultStyleSettings}
          />
          <TextField
            source="destination"
            {...defaultStyleSettings}
          />
          <TextField source="createdInArc" {...defaultStyleSettings} />
          <TextField source="createdBy" {...defaultStyleSettings} />
          <RichTextInput label="Note" source="text" margin="normal" fullWidth />
          <TextInput source="website" defaultValue={window.env.WEBSITE} disabled  {...defaultStyleSettings} />
        </SimpleShowLayout>
    </SimpleForm>
  </Edit>
);

export default RedirectEdit;
