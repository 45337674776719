import { getSlug } from './getSlug';

const transform = (campaign) => {
  if (!campaign.isDisable || !campaign.products || !campaign.products.length) {
    return campaign;
  }
  campaign.products.forEach((product) => (product.isDisable = true));
  return campaign;
};

// update displayPrice to empty string if subscriptionType is not GROUP
const updateDisplayPrice = (products = []) =>
  products.map((product) => {
    if (product.subscriptionType !== 'GROUP' && product.displayPrice) {
      product.displayPrice = '';
    }
    return product;
  });

const onCampaignSave = (oldCampaign, newCampaign, save, msg) => {
  if (newCampaign.products || newCampaign.products.length) {
    newCampaign.products.forEach((product) => {
      if (disablePayment(product.subscriptionType)) {
        product.paymentOption = '';
      }
    });
  }
  if (!newCampaign?.slugName) {
    newCampaign.slugName = getSlug(newCampaign?.campaignName);
  }
  newCampaign.products = updateSlugNames(newCampaign.products);
  newCampaign.products = updateDisplayPrice(newCampaign.products);
  if (
    oldCampaign.isDisable ||
    !newCampaign.isDisable ||
    !newCampaign.products ||
    !newCampaign.products.length
  ) {
    save({ ...newCampaign });
    return;
  }
  if (window.confirm(msg)) {
    newCampaign.products.forEach((product) => (product.isDisable = true));
    save({ ...newCampaign });
  }
};

const disablePayment = (subscriptionType) =>
  subscriptionType === 'FREE' || subscriptionType === 'GROUP';

const getNewSlugFromExisting = (slugName, existingSlugs) => {
  for (let index = 1; ; index++) {
    const newSlug = `${slugName}-${index}`;
    if (!existingSlugs.includes(newSlug)) {
      return newSlug;
    }
  }
};

const updateSlugNames = (products) => {
  if (!products || products.length === 0) {
    return products;
  }
  const slugs = [];
  const existingSlugs = products.map((product) => product.slugName);

  for (let index = 0; index < products.length; index++) {
    let slugName = products[index].slugName || getSlug(products[index].name);
    if (slugs.includes(slugName)) {
      slugName = getNewSlugFromExisting(slugName, existingSlugs);
      existingSlugs.push(slugName);
    }
    products[index].slugName = slugName;
    slugs.push(slugName);
  }
  return products;
};

export { transform, onCampaignSave, disablePayment, updateSlugNames };
