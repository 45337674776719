export function requiredDisablePast(givenDate) {
  if (!givenDate) {
    return '';
  }
  try {
    const givenTimestamp = new Date(givenDate).getTime();
    return givenTimestamp < new Date().getTime() && 'Previous date is selected';
  } catch (e) {
    return 'Incorrect date';
  }
}
