import {
  BooleanField,
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import CampaignPreviewUrlField from './CampaignPreviewUrlField';

const RedirectFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput
      label="Campaign Name"
      source="campaignName"
      defaultValue="Hello, World!"
    />
  </Filter>
);

export const CampaignList = (props) => (
  <List {...props} filters={<RedirectFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="campaignName" />
      <TextField source="slugName" />
      <TextField source="title" />
      <TextField source="subTitle" />
      <CampaignPreviewUrlField source="campaignName" label="PreviewUrl" />
      <BooleanField source="isVipps" />
      <BooleanField source="defaultCampaign" />
      <BooleanField source="isDisable" />
      <EditButton />
    </Datagrid>
  </List>
);

export default CampaignList;
