import slugify from 'slugify';

export function getSlug(name) {
  return name
    ? slugify(name, {
        strict: true,
        lower: true,
      })
    : '';
}
