module.exports = {
  custom: {
    dashboard: {
      titleCampaign: 'Guide för att skapa kampanjer:',
      infoCampaign: 'Blå text = produktnivå, svart text = kampanjnivå',
      title: 'Mentos verktyg',
      titleRedirect: 'Guide för webaliaser:',
      infoRedirect: '(shortUrl,document och urlPath)',
    },
    redirect: {
      list: 'Lista över webbalias',
    },
    copybutton: {
      copy: 'Kopiera',
    },
  },
  ra: {
    action: {
      delete: 'Radera',
      show: 'Visa',
      list: 'Lista',
      save: 'Spara',
      create: 'Skapa',
      edit: 'Redigera',
      cancel: 'Avbryt',
      refresh: 'Ladda om',
      add_filter: 'Lägg till filter',
      remove_filter: 'Ta bort filter',
      add: 'Lägg till',
      back: 'Gå tillbaka',
      bulk_actions: '%{smart_count} vald',
      clone: 'Klona',
      confirm: 'Bekräfta',
      export: 'Exportera',
      remove: 'Ta bort',
      search: 'Sök',
      sort: 'Sortera',
      undo: 'Ångra',
      unselect: 'Avmarkera',
      expand: 'Utvid',
      close: 'Stänga',
      open_menu: 'Öppna menyn',
      close_menu: 'Stäng menyn',
      clear_input_value: 'Ta bort värde',
      disable_products_confirmation: 'Kampanjen är inaktiverad. Alla produkter kommer också att inaktiveras. Är du säker på att spara ändringarna?'
    },
    boolean: {
      true: 'Ja',
      false: 'Nej',
      null: ' ',
    },
    page: {
      list: '%{name} Lista',
      edit: '%{name} #%{id}',
      show: '%{name} #%{id}',
      create: 'Skapa %{name}',
      dashboard: 'Överblick',
      error: 'Något gick fel',
      loading: 'Laddar',
      not_found: 'Hittades inte',
      empty: 'Inget %{name} ännu',
      invite: 'Vill du lägga till en?',
    },
    input: {
      file: {
        upload_several:
          'Dra och släpp filer för att ladda upp, eller tryck för att välja.',
        upload_single:
          'Dra och släpp en fil för att ladda upp den, eller tryck för att välja.',
      },
      image: {
        upload_several:
          'Drag och släpp filer för att ladda upp, eller klicka för att välja filer.',
        upload_single:
          'Drag och släpp en fil för att ladda upp, eller klicka för att välja fil',
      },
      references: {
        all_missing: 'Det gick inte att hitta referensdata.',
        many_missing:
          'Åtminstone en av de associerade referenserna finns inte längre',
        single_missing: 'Bifogad referens finns inte längre',
      },
      password: {
        toggle_visible: 'Dölj lösenord',
        toggle_hidden: 'Visa lösenord',
      },
    },
    message: {
      yes: 'Ja',
      no: 'Nej',
      are_you_sure: 'Är du säker?',
      about: 'Om',
      bulk_delete_content:
        'Är du säker på att du vill ta bort %{name}? |||| Är du säker på att du vill ta bort %{smart_count} poster?',
      bulk_delete_title:
        'Ta bort %{name} |||| Ta bort %{smart_count} %{name} objekt',
      delete_content: 'Är du säker på att du vill ta bort den här posten?',
      delete_title: 'Ta bort %{name} #%{id}',
      details: 'Detaljer',
      error: 'Ett fel inträffade och åtgärden utfördes inte',
      invalid_form: 'Formuläret är inte giltigt. Kontrollera om det finns fel',
      loading: 'Sidan laddas, vänta',
      not_found: 'Du angav fel URL eller följde en ogiltig länk',
      unsaved_changes:
        'En del av dina ändringar sparades inte. Är du säker på att du vill ignorera dem?',
    },
    navigation: {
      no_results: 'Inga resultat hittades',
      no_more_results: 'Sidnummer %{page} finns inte. Testa föregående sida.',
      page_out_of_boundaries: 'Sidnummer %{page} utanför spannet',
      page_out_from_end: 'Kan inte besöka sida efter sista sidan',
      page_out_from_begin: 'Kan inte besöka sida före första sidan',
      page_range_info: '%{offsetBegin}-%{offsetEnd} av %{total}',
      page_rows_per_page: 'Rader per sida:',
      next: 'Nästa',
      previous: 'Föregående',
      skip_nav: 'Hoppa till innehållet',
    },
    sort: {
      sort_by: 'Sortera efter %{field} %{order}',
      ASC: 'stigande',
      DESC: 'nedåtgående',
    },
    auth: {
      auth_check_error: 'Var vänlig logga in för att fortsätta',
      user_menu: 'Profil',
      username: 'Användarnamn',
      password: 'Lösenord',
      sign_in: 'Logga in',
      sign_in_error: 'Inloggning misslyckades, försök gärna igen',
      logout: 'Logga ut',
    },
    notification: {
      updated: 'Objekt uppdaterat |||| %{smart_count} objekter uppdaterat',
      created: 'Objekt skapat',
      deleted: 'Objekt raderat |||| %{smart_count} objekt har tagits bort',
      bad_item: 'Fel objekt',
      item_doesnt_exist: 'Objektet hittas inte',
      http_error: 'Kommunikationsfel med servern',
      data_provider_error: 'dataProvider fel. Se konsolen för mer information.',
      i18n_error:
        'Det gick inte att ladda översättningarna för det angivna språket',
      canceled: 'Åtgärden avbröts',
      logged_out: 'Din session har avslutats. Anslut igen.',
    },
    validation: {
      required: 'Obligatorisk',
      minLength: 'Måste vara minst %{min} tecken',
      maxLength: 'Måste vara max %{max} tecken',
      minValue: 'Måste vara minst %{min}',
      maxValue: 'Måste vara max %{max}',
      number: 'Måste vara ett nummer number',
      email: 'Måste vara en giltig e-postadress',
      oneOf: 'Måste vara en av: %{options}',
      regex: 'Måste ha ett specifikt format (regexp): %{pattern}',
    },
  },
};
