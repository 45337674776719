import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { required, SelectInput, TextInput, useUpdateMany } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { isDefined } from '../../../helpers/isDefined';

const Coupon = ({
  getSource,
  couponData,
  scopedFormData,
  prices,
  setPrices,
}) => {
  const [getCoupon, { data: couponNoList = [] }] = useUpdateMany();
  const [couponCodeDescription, setCouponCodeDescription] = useState('');
  const [couponNumberDescription, setCouponNumberDescription] = useState('');
  const [acceptOldValues, setAcceptOldValues] = useState(true);
  const { setValue } = useFormContext();
  const selectCoupon = (value) =>
    getCoupon('mc/client/coupon', { ids: [value] });

  const createChoices = (existingValue, data = []) => {
    const ids = [];
    const choices = [];
    data.forEach(({ id }) => {
      if (ids.indexOf(id) === -1) {
        ids.push(id);
        choices.push({ id, name: id });
      }
    });

    if (
      isDefined(existingValue) &&
      acceptOldValues &&
      ids.indexOf(existingValue) === -1
    ) {
      choices.push({
        id: existingValue,
        name: existingValue,
        disabled: true,
      });
    }
    choices.sort((first, second) =>
      first.name > second.name ? 1 : second.name > first.name ? -1 : 0
    );
    return choices;
  };

  const couponCodeChoices = createChoices(
    scopedFormData?.couponCode,
    couponData
  );
  const couponNoChoices = createChoices(scopedFormData?.couponNo, couponNoList);

  useEffect(() => {
    if (
      typeof scopedFormData?.couponCode !== 'undefined' &&
      scopedFormData?.couponCode !== ''
    ) {
      selectCoupon(scopedFormData?.couponCode);
    }
  }, [scopedFormData?.couponCode || '']);

  useEffect(() => {
    setCouponCodeDescription(
      couponData?.find(
        (element) => element?.product === scopedFormData?.couponCode
      )?.description
    );
  }, [couponNoList]);

  return (
    <>
      <Grid item style={{ padding: '0 16px' }} xs={12} sm={6} md={4}>
        <SelectInput
          helperText={couponCodeDescription}
          source={getSource('couponCode')}
          choices={couponCodeChoices}
          fullWidth
          label="Coupon Code"
          validate={[required()]}
          variant="standard"
          onChange={(event) => {
            if (
              !couponNoList?.find(
                (element) => element.id === scopedFormData?.couponNo
              )
            ) {
              setAcceptOldValues(false);
            }
            return selectCoupon(event.target.value);
          }}
        />
      </Grid>

      <Grid item style={{ padding: '0 16px' }} xs={12} sm={6} md={4}>
        <SelectInput
          helperText={couponNumberDescription}
          source={getSource('couponNo')}
          choices={couponNoChoices}
          fullWidth
          label="Coupon Number"
          validate={[required()]}
          variant="standard"
          onChange={(event) => {
            const nameInit = `${event.target.name.replace('couponNo', '')}`;
            const price = parseFloat(
              couponNoList.find(
                (element) => element.couponNumber === event.target.value
              )?.couponPrice || 0
            );
            const vat =
              couponData?.find(
                (element) => element.product === scopedFormData.couponCode
              )?.vatPercent || 0;
            setValue(`${nameInit}price`, price);
            setValue(`${nameInit}vat`, vat);

            const priceInfo = prices.find(
              (element) =>
                element.couponCode === scopedFormData.couponCode &&
                element.couponNo === event.target.value
            );

            // Update price of existing coupon code + coupon number pair
            if (priceInfo) {
              var pricesTemp = prices;
              pricesTemp[pricesTemp.indexOf(priceInfo)].price = price;
              setPrices(pricesTemp);
            } else {
              // Add new pair
              setPrices([
                ...prices,
                {
                  couponCode: scopedFormData?.couponCode,
                  couponNo: event.target.value,
                  price: price,
                },
              ]);
            }

            setCouponNumberDescription(
              couponNoList?.find(
                (element) => element?.couponNumber === event.target.value
              )?.description
            );
          }}
        />
      </Grid>

      <Grid item style={{ padding: '0 16px' }} xs={12} sm={6} md={4}>
        <TextInput
          defaultValue=""
          fullWidth
          label="Company Code"
          source={getSource('companyCode')}
          validate={[required()]}
          variant="standard"
        />
      </Grid>
    </>
  );
};
export default Coupon;
