import { Grid } from '@material-ui/core';
import { TextInput } from 'react-admin';

const Image = ({ getSource }) => (
  <Grid item style={{ padding: '0 16px' }} xs={12}>
    <TextInput
      fullWidth
      label="ImageUrl"
      source={getSource('imageUrl')}
      variant="standard"
    />
  </Grid>
);

export default Image;
