import {
  Datagrid,
  DeleteButton,
  Filter,
  List,
  TextField,
  TextInput,
} from 'react-admin';
import PreviewUrlField from './PreviewUrlField';

const RedirectFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput label="Alias" source="shortUrl" defaultValue="Hello, World!" />
  </Filter>
);

export const RedirectList = (props) => (
  <List {...props} filters={<RedirectFilter />}>
    <Datagrid rowClick="edit">
      <PreviewUrlField
        source="shortUrl"
        prefix={window.env.WEBSITE_URL}
        label="From/Alias"
      />
      <TextField source="document" label="Type" />
      <PreviewUrlField
        source="destination"
        prefix={window.env.ARC_COMPOSER_EDIT}
        label="Destination"
      />
      <TextField source="createdInArc" />
      <TextField source="createdBy" />
      <DeleteButton />
    </Datagrid>
  </List>
);

export default RedirectList;
