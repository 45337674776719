import { defaultTheme } from 'react-admin';
import { grey } from '@material-ui/core/colors';

export const darkTheme = {
  ...defaultTheme,
  palette: {
    type: 'dark',
    secondary: {
      main: '#2196f3',
    },
  },
};

export const lightTheme = {
  ...defaultTheme,
  palette: {
    secondary: {
      main: grey[900],
    },
    background: {
      default: '#fafafb',
    },
  },
};
