import polyglotI18nProvider from 'ra-i18n-polyglot';
import norwegianMessages from '../languages/NorwegianMessages';
import swedishMessages from '../languages/SwedishMessages';

export function getLanguageProvider() {
  if (typeof window.env != 'object') {
    window.env = {};
    window.env.LANGUAGE = 'no';
  }

  const language = window.env.LANGUAGE;
  const messages = {
    no: norwegianMessages,
    sv: swedishMessages,
  };
  var message = messages[language];
  return polyglotI18nProvider(() => message, language, {
      allowMissing: true,
      onMissingKey: (missingKey) => {return missingKey;},
    });
}
