import { Grid } from '@material-ui/core';
import { NumberInput, required, TextInput } from 'react-admin';

const Price = ({ getSource }) => (
  <>
    <Grid item style={{ padding: '0 16px' }} xs={12} sm={6} md={4}>
      <TextInput
        fullWidth
        label="Price Textfield"
        source={getSource('pricePeriod')}
        validate={[required()]}
        variant="standard"
      />
    </Grid>

    <Grid item style={{ padding: '0 16px' }} xs={12} sm={6} md={4}>
      <NumberInput
        fullWidth
        label="Price"
        options={{ maximumFractionDigits: 2 }}
        source={getSource('price')}
        variant="standard"
        defaultValue={0}
        disabled
      />
    </Grid>

    <Grid item style={{ padding: '0 16px' }} xs={12} sm={6} md={4}>
      <TextInput
        fullWidth
        label="Vat"
        source={getSource('vat')}
        defaultValue=""
        variant="standard"
        disabled
      />
    </Grid>
    <Grid item style={{ padding: '0 16px' }} xs={12} sm={6} md={4}>
      <TextInput
        fullWidth
        label="Renewal price text (entire text, not only the price)"
        source={getSource('renewalPrice')}
        placeholder='Fornyes til 249 kr/mnd'
        defaultValue=""
        variant="standard"
      />
    </Grid>
    <Grid item style={{ padding: '0 16px' }} xs={12} sm={6} md={4}>
      <TextInput
        fullWidth
        label="Disclaimer text"
        source={getSource('disclaimer')}
        placeholder='Fornyes til 249 kr/mnd'
        defaultValue=""
        variant="standard"
      />
    </Grid>
  </>
);

export default Price;
