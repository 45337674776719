import { Datagrid, List, FunctionField, TextField, useGetList } from 'react-admin';

export const CampaignVerifyList = (props) => {
    const { data: campaigns } = useGetList('campaign', { pagination: { page: 1, perPage: 1000 } });

    const campaignNames = {};
    if (campaigns) {
        campaigns.forEach(({ id, campaignName }) => campaignNames[id] = campaignName);
    }
    const { data, total } = useGetList('campaign/verify', {  pagination: { page: 1, perPage: 1000 } });

    return (<List {...props} pagination={null}>
        <Datagrid bulkActionButtons={false} data={data} total={total}>
            <FunctionField source="campaignId" label="Campaign" render={({ campaignId }) => <span>{campaignNames[campaignId] || campaignId}</span>}/>
            <TextField source="productName" label="Product"/>
            <FunctionField source="operation" label="operation" render={({ operation }) => <span style={{ color: operation === 'MISSING' ? 'red' : 'green' }}>{operation}</span>}/>
            <TextField source="savedValue" label="Current Value"/>
            <TextField source="mediaconnectValue" label="Updated Value" />
            <FunctionField source="Campaign Page" render={({ campaignId }) => <a href={`/campaign/${campaignId}`}>Go to campaign</a>}/>
        </Datagrid>
    </List>);
};

export default CampaignVerifyList;