import copy from 'copy-to-clipboard';
import { Button, useTranslate, useRecordContext } from 'react-admin';

const CopyButton = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const handleClick = () => {
    const regex = record.regex;
    copy(regex);
  };

  return (
    <Button label={translate('custom.copybutton.copy')} onClick={handleClick} />
  );
};
export default CopyButton;
