function getUser(userInfo) {
  if (!userInfo) return '';
  return userInfo?.name ? userInfo.name : userInfo.email;
}

const authProvider = {
  login: async ({ username, password }) => {
    const opts = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
      body: 'username=' + username + '&password=' + password,
      mode: 'cors',
    };
    const response = await fetch(
      window.env.API_URL_ARC + '/mentos-admin/authenticate',
      opts
    );
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const authenticationInfo = await response.json();
    localStorage.setItem('token', authenticationInfo.access_token);
    localStorage.setItem('user', getUser(authenticationInfo.user_info));
    return Promise.resolve();
  },

  logout: () => {
    localStorage.clear();
    return Promise.resolve();
  },

  checkAuth: () =>
    localStorage.getItem('token') ? Promise.resolve() : Promise.reject(),

  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => Promise.resolve(),
};

export default authProvider;
