import { RichTextInput } from 'ra-input-rich-text';
import {
  AutocompleteInput,
  Create,
  FormDataConsumer,
  SimpleForm,
  TextInput,
} from 'react-admin';

function formatUrl(shortUrl, document) {
  // Do not change value if shortUrl or document is not set
  if (!shortUrl || !document) return shortUrl;

  // Remove leading and trailing slashes if shortUrl
  if (document === 'shortUrl') return shortUrl.replace(/(?:^\/)|(?:\/$)/g, '');

  // If not shortUrl, always have leading slash
  if (!shortUrl.startsWith('/')) shortUrl = '/' + shortUrl;

  // Have trailing slash except if string contains '.'
  if (shortUrl.includes('.')) {
    return shortUrl.replace(/\/$/g, '');
  } else if (!shortUrl.endsWith('/')) {
    return shortUrl + '/';
  }

  return shortUrl;
}

export const defaultStyleSettings = {
  style: { width: 256 },
  margin: 'normal',
  variant: 'outlined',
};

export const RedirectCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput
        source="shortUrl"
        label="From/Alias"
        helperText="Input example: /kundeservice"
        {...defaultStyleSettings}
      />
      <AutocompleteInput
        source="document"
        label="Type"
        choices={[
          { id: 'document', name: 'Redirect to ARC article (document)' },
          { id: 'urlPath', name: 'Redirect from A to B (urlPath)' },
          { id: 'shortUrl', name: 'Change article url (shortUrl)' },
        ]}
        {...defaultStyleSettings}
      />
      <TextInput
        source="destination"
        helperText="if type = shortUrl or document ex:JFJXP3QZGJG45HDBYIUNEVBFA4, if type = urlPath ex: /abonnement"
        {...defaultStyleSettings}
      />
      {localStorage.user && (
        <TextInput
          source="createdBy"
          defaultValue={localStorage.user}
          disabled
         {...defaultStyleSettings}
        />
      )}
      <RichTextInput
        label="Note"
        source="text"
        margin="normal"
        variant="outlined"
        fullWidth
      />
      <TextInput
        source="website"
        defaultValue={window.env.WEBSITE}
        disabled
        {...defaultStyleSettings}
      />
      <FormDataConsumer>
        {({ formData }) => {
          formData.shortUrl = formatUrl(formData.shortUrl, formData.document);
        }}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default RedirectCreate;
