import { HttpError } from 'react-admin';
import restProvider from './RestProvider';

const fetchJson = async (url, options = {}) => {
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: 'application/json',
    });
  requestHeaders.set('Content-Type', 'application/json');
  const token = localStorage.getItem('token');
  requestHeaders.set('Authorization', 'Bearer ' + token);
  const response = await fetch(url, { ...options, headers: requestHeaders });
  const text = await response.text();
  const o = {
    status: response.status,
    statusText: response.statusText,
    headers: response.headers,
    body: text,
  };
  const status = o.status,
    statusText = o.statusText,
    headers = o.headers,
    body = o.body;
  let json;
  try {
    json = JSON.parse(body);
  } catch (e) {
    // not json, no big deal
  }
  if (status < 200 || status >= 300) {
    if (json && json.error_message)
      return Promise.reject(new HttpError(json.error_message));
    return Promise.reject(new HttpError(statusText, status, json));
  }
  return Promise.resolve({
    status: status,
    headers: headers,
    body: body,
    json: json,
  });
};

const httpClient = (url, options = {}) => {
  return fetchJson(url, options);
};

const dataProviders = [
  {
    dataProvider: restProvider(
      window.env.API_URL_CAMPAIGN + '/api2',
      httpClient
    ),
    resources: ['campaign'],
  },
  {
    dataProvider: restProvider(window.env.API_URL_ARC + '/api2', httpClient),
    resources: ['webAlias'],
  },
  {
    dataProvider: restProvider(window.env.API_URL_ARC + '/api2', httpClient),
    resources: ['regex'],
  },
  {
    dataProvider: restProvider(window.env.API_URL_CAMPAIGN + '/api2', httpClient),
    resources: ['campaign/verify'],
  },
  {
    dataProvider: restProvider(
      window.env.API_URL_CAMPAIGN + '/api2',
      httpClient
    ),
    resources: ['mc/client/product'],
  },
  {
    dataProvider: restProvider(
      window.env.API_URL_CAMPAIGN + '/api2',
      httpClient
    ),
    resources: ['mc/client/coupon'],
  },
];

export default function dataProvider(type, resource, params) {
  const dataProviderMapping = dataProviders.find((dp) =>
    dp.resources.includes(resource)
  );

  switch (type) {
    case 'GET_LIST':
      return dataProviderMapping.dataProvider.getList(resource, params);
    case 'GET_ONE':
      return dataProviderMapping.dataProvider.getOne(resource, params);
    case 'GET_MANY':
      return dataProviderMapping.dataProvider.getMany(resource, params);
    case 'GET_MANY_REFERENCE':
      return dataProviderMapping.dataProvider.getManyReference(
        resource,
        params
      );
    case 'UPDATE':
      return dataProviderMapping.dataProvider.update(resource, params);
    case 'UPDATE_MANY':
      // TODO: This is a walk away code to use provider data as mutation
      // We should try a way to remove this and add it in any `GET` method
      if(resource === 'mc/client/coupon') {
        return dataProviderMapping.dataProvider.getManyWithId(resource, params);
      }
      return dataProviderMapping.dataProvider.updateMany(resource, params);
    case 'CREATE':
      return dataProviderMapping.dataProvider.create(resource, params);
    case 'DELETE':
      return dataProviderMapping.dataProvider.delete(resource, params);
    case 'DELETE_MANY':
      return dataProviderMapping.dataProvider.deleteMany(resource, params);
    // no default
  }
}
