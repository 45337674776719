import Language from '@mui/icons-material/Language';
import MonetizationOn from '@mui/icons-material/MonetizationOn';
import TextFields from '@mui/icons-material/TextFields';
import ChangeCircle from '@mui/icons-material/ChangeCircle';
import { createBrowserHistory  } from 'history';
import { Admin, Resource } from 'react-admin';
import Dashboard from '../../dashboard/Dashboard';
import { getLanguageProvider } from '../../helpers/getLanguageProvider';
import authProvider from '../../providers/authentication-provider/AuthProvider';
import dataProviders from '../../providers/data-provider/DataProviders';
import { lightTheme } from '../../style/theme';
import { CampaignCreate, CampaignEdit, CampaignList } from '../campaign';
import {
  RedirectCreate,
  RedirectEdit,
  RedirectList,
} from '../webalias/redirect';
import { RegexList } from '../webalias/regex';
import CampaignVerifyList from '../campaignVerify';

const history = createBrowserHistory();
const i18nProvider = getLanguageProvider();

const App = () => (
  <Admin
    title="Mentos"
    dashboard={Dashboard}
    dataProvider={dataProviders}
    authProvider={authProvider}
    i18nProvider={i18nProvider}
    history={history}
    theme={lightTheme}
  >
    <Resource
      name="campaign"
      list={CampaignList}
      edit={CampaignEdit}
      create={CampaignCreate}
      icon={MonetizationOn}
    />
    <Resource
      name="webAlias"
      list={RedirectList}
      edit={RedirectEdit}
      create={RedirectCreate}
      icon={Language}
    />
    <Resource name="regex" list={RegexList} icon={TextFields} />
    <Resource name="campaign/verify" options={{ label: 'Coupon Mismatch' }} list={CampaignVerifyList} icon={ChangeCircle} />
  </Admin>
);

export default App;
