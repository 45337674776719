import { Grid } from '@material-ui/core';
import { BooleanInput } from 'react-admin';

const Options = ({
  getSource,
  subscriptionType,
  holdBackTelemarketing = false,
  skipUserCreation = false,
}) => (
  <Grid item style={{ padding: '0 16px' }} xs={12}>
    <BooleanInput
      fullWidth
      label="Disable this product"
      source={getSource('isDisable')}
    />
    <BooleanInput
      fullWidth
      label="Need street address?"
      source={getSource('paper')}
    />
    <BooleanInput
      fullWidth
      label="Show field for company/organisation"
      source={getSource('companyOrOrganisation')}
    />
    <BooleanInput
      fullWidth
      label="Show start date for gift subscription"
      source={getSource('showStartDate')}
    />
    <BooleanInput
      fullWidth
      label="Skip user creation"
      source={getSource('skipUserCreation')}
      checked={skipUserCreation}
      disabled={holdBackTelemarketing}
    />
    <BooleanInput
      fullWidth
      label="Hold back Telemarketing"
      source={getSource('holdBackTelemarketing')}
    />
    <BooleanInput
      fullWidth
      label="Email required? "
      source={getSource('isEmailRequired')}
      defaultValue={true}
    />
    {['GIFT', 'GROUP'].includes(subscriptionType) && <BooleanInput
      fullWidth
      label="Receiver email required?"
      source={getSource('isReceiverEmailRequired')}
      defaultValue={true}
    />}
  </Grid>
);

export default Options;
