import { Datagrid, List, TextField } from 'react-admin';
import CopyButton from './CopyButton';

export const RegexList = (props) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="document" label="Redirect To" />
      <TextField source="regex" label="Resolver Regex" />
      <CopyButton />
    </Datagrid>
  </List>
);

export default RegexList;
