import { Grid } from '@material-ui/core';
import { required, SelectInput, TextInput } from 'react-admin';
import { isDefined } from '../../../helpers/isDefined';

const BasicInfo = ({ getSource, scopedFormData }) => (
  <>
    <Grid item style={{ padding: '0 16px 16px' }} xs={12} md={6}>
      <TextInput
        fullWidth
        helperText={`Slug: ${
          isDefined(scopedFormData?.slugName) ? scopedFormData.slugName : ''
        }`}
        label="Name"
        source={getSource('name')}
        style={{ flex: 2 }}
        validate={[required()]}
        variant="standard"
      />
      <div style={{ display: 'none' }}>
        <TextInput source={getSource('slugName')} defaultValue="" />
      </div>
    </Grid>

    <Grid item style={{ padding: '0 16px 16px' }} xs={12} md={6}>
      <SelectInput
        choices={[
          { id: 'REGULAR', name: 'Regular subscription' },
          { id: 'GROUP', name: 'Group subscription' },
          { id: 'FREE', name: 'Free subscription' },
          { id: 'ONBOARDING', name: 'Onboarding subscription' },
          { id: 'GIFT', name: 'Gift subscription' },
        ]}
        defaultValue="REGULAR"
        fullWidth
        label="Subscription type"
        source={getSource('subscriptionType')}
        validate={[required()]}
        variant="standard"
      />
    </Grid>

    <Grid item style={{ padding: '0 16px 16px' }} xs={12} md={6}>
      <TextInput fullWidth label="Promotion Text" source={getSource('promotionText')}  variant="standard" />
    </Grid>
  </>
);

export default BasicInfo;
