import { Grid } from '@material-ui/core';
import { TextInput } from 'react-admin';
import { ColorInput } from 'react-admin-color-picker';
import { isDefined } from '../../../helpers/isDefined';

const ButtonInfo = ({ getSource, scopedFormData }) => (
  <>
    <Grid item style={{ padding: '0 16px' }} xs={12} md={6}>
      <TextInput
        fullWidth
        label="Button Text"
        source={getSource('buttonText')}
        variant="standard"
      />
    </Grid>
    <Grid item style={{ padding: '0 16px' }} xs={12} md={6}>
      <div style={{ display: 'flex', marginTop: -10 }}>
        <ColorInput
          fullWidth
          label="Button Colour"
          source={getSource('buttonColour')}
          variant="standard"
        />
        <div
          style={{
            height: 48,
            width: 48,
            background: isDefined(scopedFormData?.buttonColour)
              ? scopedFormData.buttonColour
              : '#fff',
            border: '1px solid rgba(0, 0, 0, 0.42)',
            borderRadius: 3,
            marginTop: 16,
            marginLeft: 32,
            flex: 1,
          }}
        />
      </div>
    </Grid>
  </>
);

export default ButtonInfo;
