import { Card, CardContent, Typography } from '@material-ui/core';
import { Title, useTranslate } from 'react-admin';
import mainLogo from '../assets/KampanjeGuide.PNG';
import redirectLogo1 from '../assets/RedirectGuide1.PNG';
import redirectLogo2 from '../assets/RedirectGuide2.PNG';
import redirectLogo3 from '../assets/RedirectGuide3.PNG';
import redirectLogo4 from '../assets/RedirectGuide4.PNG';
import redirectLogo5 from '../assets/RedirectGuide5.PNG';

const Dashboard = () => {
  const translate = useTranslate();

  return (
    <>
      <Title title={translate('custom.dashboard.title')} />
      <Card>
        <CardContent>
          <Typography variant="h3">
            {translate('custom.dashboard.titleCampaign')}
          </Typography>
          <Typography variant="h6">
            {translate('custom.dashboard.infoCampaign')}
          </Typography>
          <Typography paragraph>
            <br />
          </Typography>
          <Typography paragraph>
            <img
              src={mainLogo}
              width={'1122,4px'}
              height={'629,2px'}
              alt="component overview"
            />
          </Typography>
          <Typography paragraph>
            <br />
          </Typography>
          <Typography variant="h3">
            {translate('custom.dashboard.titleRedirect')}
          </Typography>
          <Typography variant="h6">
            {translate('custom.dashboard.infoRedirect')}
          </Typography>
          <Typography paragraph>
            <br />
          </Typography>
          <Typography paragraph>
            <img
              src={redirectLogo1}
              width={'876px'}
              height={'828px'}
              alt="user guide 1"
            />
            <img
              src={redirectLogo2}
              width={'876px'}
              height={'828px'}
              alt="user guide 2"
            />
            <img
              src={redirectLogo3}
              width={'876px'}
              height={'828px'}
              alt="user guide 3"
            />
            <img
              src={redirectLogo4}
              width={'876px'}
              height={'828px'}
              alt="user guide 4"
            />
            <img
              src={redirectLogo5}
              width={'876px'}
              height={'828px'}
              alt="user guide 5"
            />
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

export default Dashboard;
